import React from 'react';
import styled from 'styled-components';
import SEO from '../components/seo';
import Layout from '../components/Layout';

const AboutText = styled.p`
  max-width: 550px;
`;

function AboutPage() {
  return (
    <>
      <SEO title="Hvem er vi" />
      <Layout>
        <section className="explore-container">
          <h1>Ideen bag Zooticket</h1>
          <AboutText>
            Det har aldrig været nemt, at finde begivenheder og events i
            Danmark. Slet ikke, hvis man ikke vidste hvad der skete på forhånd.
          </AboutText>
          <AboutText>
            Besøger man venner og/eller familie i en bestemt by, og vil
            egentligt gerne vide hvad man kunne foretage, mens man var på besøg?
            Det kan være, man gerne vil se hvad der sker på ens fødselsdag? Der
            er mange muligheder, hvor det har været svært at undersøge.
          </AboutText>
          <AboutText>
            Det er netop dette, vi vil forsøge at gøre nemmere med Zooticket DK.
            Vi samler begivenheder og events fra forskellige billetudbydere.
            Samtidig giver vi muligheden, for at lave specifikke søgninger efter
            byer, datoer, kategorier mm. Vi er ikke selv billetudbydere eller
            arrangør for begivenhederne. Dette er oprettet i samarbejde med
            nogle af landets billetudbydere.
          </AboutText>
        </section>
      </Layout>
    </>
  );
}

export default AboutPage;
